<template>
  <section class="tg-authorization" data-t="tg-authorization">
    <MFormHeader no-bottom-line>
      <span class="title">{{ t('telegram.registration') }}</span>
    </MFormHeader>
    <div class="content">
      <p class="subtitle">{{ t('telegram.subtitle') }}</p>
      <PromocodeInput
        v-model="promocodeInputValue"
        type="ghost"
        is-mobile
        :promocode-error-message="promocodeErrorMessage"
        class="promo"
      />
      <UserAgreement v-model="isAgreementChecked" class="agreement" is-mobile />
      <StButton
        size="l"
        type="custom-color"
        custom-color="#2d9fff"
        icon="telegram-solid"
        is-left-icon
        :disabled="!isAgreementChecked"
        :loading="isTgProcessing"
        @click="handleTelegramSignIn"
      >
        {{ t('telegram.register') }}
      </StButton>
      <StButton class="have-account" type="ghost" @click="emit('login')">
        {{ t('telegram.haveAccount') }}
      </StButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useTelegram } from '@st/telegram/composables/useTelegram'
import MFormHeader from '../parts/MFormHeader.vue'
import PromocodeInput from '../RegistrationForm/parts/PromocodeInput.vue'
import UserAgreement from '../RegistrationForm/parts/UserAgreement.vue'

const emit = defineEmits<{
  (e: 'login'): void
}>()
const { t, locale } = useI18n()
const router = useRouter()
const isAgreementChecked = ref(true)

const promocodeInputValue = ref('')
const { convertedReferralCode, convertedPromocodeName, promocodeErrorMessage } =
  useCheckPromocode(promocodeInputValue)

const { open } = useToast()
const { auth } = useTelegram()
const { tryToAuthenticate } = useUserStore()
const isTgProcessing = ref(false)

const providersSignUpParams = computed(() =>
  convertedReferralCode.value
    ? {
        language: locale.value,
        referralCode: convertedReferralCode.value,
      }
    : {
        language: locale.value,
        promocodeName: convertedPromocodeName.value,
      },
)

async function handleTelegramSignIn() {
  try {
    isTgProcessing.value = true
    await auth(providersSignUpParams.value as any)
    await tryToAuthenticate()
    router.replace({ query: { modal: 'register', tab: 'bonus' } })
  } catch (e) {
    open({
      label: t('authorization.errorMessages.somethingWentWrong'),
      type: 'negative',
    })
    console.error('telegram auth error:', e)
  } finally {
    isTgProcessing.value = false
  }
}
</script>
<style scoped>
.tg-authorization {
  display: flex;
  flex-direction: column;

  height: 100%;
  padding-top: var(--spacing-200);

  background: linear-gradient(
    180deg,
    rgb(48 131 255 / 50%) 14.5%,
    rgb(193 229 255 / 0%) 40%
  );
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;

  .title {
    width: 100%;
    padding: 0 var(--spacing-100);
    font: var(--mobile-title-2-semibold);
    text-align: start;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--spacing-200);

    &:deep(.st-button) {
      width: 100%;
    }
  }

  .subtitle {
    align-self: flex-start;

    max-width: 328px;
    margin: 0;
    margin-bottom: var(--spacing-250);
    /* stylelint-disable */
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    /* stylelint-enable */
    text-align: start;
  }

  .agreement {
    margin: var(--spacing-300) 0;
  }

  .promo {
    width: 100%;

    &:deep(.st-button) {
      color: var(--text-tertiary);
    }
  }

  .have-account {
    margin-top: var(--spacing-150);
    margin-bottom: var(--spacing-250);
  }
}
</style>
