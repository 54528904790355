<template>
  <MTelegramAuthorization
    v-if="showTelegramRegistration"
    @login="selectedTab = 'login'"
  />
  <section v-else class="authorization">
    <MFormHeader v-if="!currentStep" class="header" data-t="tabs">
      <StTabs
        v-model="selectedTab"
        type="mobile-l"
        :tabs="tabs"
        data-t="auth-tab-l7o7"
      />
    </MFormHeader>
    <div class="content">
      <AuthorizationForm
        v-if="selectedTab === 'login'"
        is-mobile
        @step-changed="handleStepChange"
      />
      <RegistrationForm v-else is-mobile @step-changed="handleStepChange" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useTelegram } from '@st/telegram/composables/useTelegram'
import AuthorizationForm from '../AuthorizationForm/AuthorizationForm.vue'
import RegistrationForm from '../RegistrationForm/RegistrationForm.vue'
import MFormHeader from '../parts/MFormHeader.vue'
import MTelegramAuthorization from '../MTelegramAuthorization/MTelegramAuthorization.vue'

type AuthTab = 'login' | 'register'
const { t } = useI18n()
const route = useRoute()
const { isTelegramMiniApp } = useTelegram()

const drawerName = computed(() => route.query.modal as AuthTab | undefined)
const selectedTab = ref<AuthTab>(drawerName.value ?? 'login')
const tabs = [
  {
    id: 'login',
    label: t('authorization.login'),
  },
  {
    id: 'register',
    label: t('authorization.registration'),
  },
]
const currentStep = ref(0)

function handleStepChange(value: number) {
  currentStep.value = value
}

const tab = useRouteQuery('tab')
const shownBonus = computed(() => tab.value === 'bonus')

const showTelegramRegistration = computed(
  () =>
    isTelegramMiniApp.value &&
    selectedTab.value === 'register' &&
    !shownBonus.value,
)
</script>
<style scoped>
.authorization {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: var(--spacing-200);
}

.content {
  flex-grow: 1;
}
</style>
